h1, h2, h3, p, a, input, label, select, option, button, span, ul, ol, li, div {
    font-family: Poppins, -apple-system, system-ui, sans-serif;
    font-size: 14px;
    font-weight: 400;
}

textarea {
    font-family: 'Recursive Mono', -apple-system, system-ui, monospace;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;

    /*--mono: "MONO" 1; !* Monospace: Sans (natural-width) or Mono (fixed-width) *!*/
    /*--casl: "CASL" 0; !* Casual: Linear to Casual *!*/
    /*--wght: "wght" 500; !* Weight: Light to ExtraBlack; usually set with `font-weight` *!*/
    /*--slnt: "slnt" 0; !* Slant: 0 to -15 degrees, auto cursive at -14 *!*/
    /*--CRSV: "CRSV" 0.5; !* Cursive: always roman, auto substitution, or always cursive *!*/

    /* apply axis values with CSS custom properties */
    /*font-variation-settings: var(--mono), var(--casl), var(--wght), var(--slnt), var(--CRSV);*/
}

img {
    -webkit-user-drag: none;
}

body {
    --white-filter: invert(100%);

    --blue: rgb(0, 122, 255);
    --blue-filter: invert(30%) sepia(92%) saturate(2405%) hue-rotate(199deg) brightness(101%) contrast(109%);
    --clear-blue: rgba(0, 122, 255, 0.1);
    --clear-blue-3: rgba(0, 122, 255, 0.3);
    --dark-clear-blue: rgba(0, 122, 255, 0.5);
    --light-blue: rgb(178, 215, 255);
    --clear-light-blue: rgba(178, 215, 255, 0.1);

    --green: rgb(40, 205, 65);
    --green-filter: invert(73%) sepia(20%) saturate(1613%) hue-rotate(74deg) brightness(84%) contrast(104%);
    --clear-green: rgba(40, 205, 65, 0.1);
    --light-green: rgb(169, 241, 180);

    --red: rgb(255, 59, 48);
    --red-filter: invert(28%) sepia(41%) saturate(3531%) hue-rotate(342deg) brightness(113%) contrast(103%);
    --clear-red: rgba(255, 59, 48, 0.1);

    --yellow: rgb(255, 204, 0);
    --clear-yellow: rgba(255, 204, 0, 0.1);

    --orange: rgb(255, 149, 0);
    --orange-filter: invert(76%) sepia(31%) saturate(7305%) hue-rotate(358deg) brightness(99%) contrast(108%);
    --clear-orange: rgba(255, 149, 0, 0.1);

    --gray: rgb(142, 142, 147);
    --gray-filter: invert(65%) sepia(4%) saturate(248%) hue-rotate(202deg) brightness(86%) contrast(97%);
    --clear-gray: rgba(142, 142, 147, 0.1);
    --clear-gray-3: rgba(142, 142, 147, 0.3);
    --light-gray: #f8f8f8;
    --dark-gray: rgb(32, 32, 32);
    --dark-gray-filter: invert(9%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(104%) contrast(93%);

    --low-clear-dark-gray: rgba(32, 32, 32, 0.6);
    --clear-black: rgba(0, 0, 0, 0.3);

    --background: white;
    --background-filter: var(--white-filter);
    --foreground: var(--blue);
    --clear-foreground: var(--clear-blue);
    --foreground-filter: var(--blue-filter);

    /*--shadow-off: 0 5px 20px 1px rgba(0, 0, 0, 0.2), inset 0 0 0 0 var(--clear-blue-3);*/
    /*--shadow-on: 0 0 0 0 rgba(0, 0, 0, 0.2), inset 0 5px 20px 1px var(--clear-blue-3);*/
    --shadow-off: none;
    --shadow-on: none;

    --no-shadow-bulge: inset 0 0 0 0 #d1d9e6, inset 0 0 0 0 #ffffff;
    --shadow-bulge: inset -1px -1px 3px 0 #d1d9e6, inset 1px 1px 3px 0 #ffffff;
    --shadow-color: #d1d9e6;
}

body.style-neumorphic {
    --background: #ecf0f3;
    --background-filter: invert(91%) sepia(2%) saturate(301%) hue-rotate(163deg) brightness(106%) contrast(93%); /* loss: 0.2 */
    --foreground: hsl(206, 90%, 50%);
    --clear-foreground: hsla(206, 90%, 50%, 0.1);
    --foreground-filter: invert(35%) sepia(53%) saturate(3780%) hue-rotate(191deg) brightness(110%) contrast(90%);

    --shadow-off: 10px 10px 20px 0 #d1d9e6, -10px -10px 20px 0 #ffffff, inset 0 0 0 0 #d1d9e6, inset 0 0 0 0 #ffffff;
    --shadow-on: 0 0 0 0 #d1d9e6, 0 0 0 0 #ffffff, inset 5px 5px 10px 0 #d1d9e6, inset -5px -5px 10px 0 #ffffff;
    --shallow-shadow-off: 5px 5px 10px 0 #d1d9e6, -5px -5px 10px 0 #ffffff, inset 0 0 0 0 #d1d9e6, inset 0 0 0 0 #ffffff;
    --shallow-shadow-on: 0 0 0 0 #d1d9e6, 0 0 0 0 #ffffff, inset 3px 3px 5px 0 #d1d9e6, inset -3px -3px 5px 0 #ffffff;
}

/*body.dark {*/
/*    --blue: rgb(10, 132, 255);*/
/*    --clear-blue: rgba(10, 132, 255, 0.1);*/
/*    --green: rgb(50, 215, 75);*/
/*    --clear-green: rgba(50, 215, 75, 0.1);*/
/*    --red: rgb(255, 69, 58);*/
/*    --clear-red: rgba(255, 69, 58, 0.1);*/
/*    --gray: rgb(152, 152, 157);*/
/*    --clear-gray: rgba(152, 152, 157, 0.1);*/
/*}*/


body {
    --nav-background: var(--light-gray);

    --file-item-background-on: var(--clear-blue);
    --file-item-hover-background: var(--clear-blue);
    --file-item-padding: 10px;
    --file-item-border-off: 4px solid transparent;
    --file-item-border-on: 4px solid var(--light-blue);

    --switch-item-background-on: var(--clear-foreground);
    --switch-item-background-off: transparent;
    --switch-item-foreground-on: var(--foreground);
    --switch-item-foreground-off: var(--foreground);
    --switch-item-foreground-filter-on: var(--foreground-filter);
    --switch-item-foreground-filter-off: var(--foreground-filter);
    --switch-item-shadow-on: none;
    --switch-item-shadow-off: none;

    --floating-bar-inner-shadow: var(--no-shadow-bulge);

    --menu-item-background-on: var(--clear-foreground);
    --menu-item-background-off: var(--clear-gray);
    --menu-item-shadow-on: none;
    --menu-item-shadow-off: none;
    --button-shadow: none;
}

body.style-neumorphic {
    --nav-background: var(--background);

    --file-item-background-on: #f9fafb;
    --file-item-hover-background: transparent;
    --file-item-padding: 14px;
    --file-item-border-off: 0 solid transparent;
    --file-item-border-on: 0 solid transparent;

    --switch-item-background-on: var(--background);
    --switch-item-background-off: var(--background);
    --switch-item-foreground-on: var(--foreground);
    --switch-item-foreground-off: var(--foreground);
    --switch-item-foreground-filter-on: var(--foreground-filter);
    --switch-item-foreground-filter-off: var(--foreground-filter);
    --switch-item-shadow-on: var(--shallow-shadow-on);
    --switch-item-shadow-off: var(--shallow-shadow-off);

    --floating-bar-inner-shadow: var(--shadow-bulge);

    --menu-item-background-on: var(--background);
    --menu-item-background-off: var(--background);
    --menu-item-shadow-on: var(--shallow-shadow-on);
    --menu-item-shadow-off: var(--shallow-shadow-off);
    --button-shadow: var(--shallow-shadow-off);
}


button {
    user-select: none;
    -webkit-user-select: none;
}

.hidden {
    display: none !important;
}

.gradient {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.3s;
}

.gradient.scale {
    transition: opacity 0.3s, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1.4);
}

.gradient.invisible {
    pointer-events: none;
    opacity: 0;
}

.gradient.invisible.scale {
    transform: scale(0.1);
}

.w20 {
    width: 20px;
}

.w50 {
    width: 50px;
}

.cancel-btn, .submit-btn, .waiting-btn, .destroy-btn, .select-btn {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 36px;
    padding: 0 15px;
    outline: none;
    margin: 14px 0 0;
    border-radius: 5px;
    border: 2px solid transparent;
    cursor: pointer;
}

.cancel-btn {
    color: var(--foreground);
    background-color: transparent;
    border: 2px solid var(--foreground);
}

.submit-btn {
    color: var(--background);
    background-color: var(--foreground);
    border: 2px solid var(--foreground);
}

.waiting-btn, .submit-btn:disabled {
    cursor: wait;
    color: grey;
    background-color: var(--clear-gray);
    border: 2px solid var(--clear-gray);
}

.destroy-btn {
    color: var(--background);
    background-color: var(--red);
    border: 2px solid var(--red);
}

.submit-btn.green {
    background-color: var(--green);
    border: 2px solid var(--green);
}

.submit-btn.orange {
    background-color: var(--orange);
    border: 2px solid var(--orange);
}

/* select btn */

img.select-btn.blue.on {
    filter: var(--blue-filter);
}

img.select-btn.blue.off {
    filter: none;
}

.select-btn.blue.on {
    color: var(--background);
    background-color: var(--blue);
}

.select-btn.blue.off {
    color: var(--blue);
    background-color: var(--clear-blue);
    border: 2px solid var(--blue);
}

/*img.select-btn.green.on {*/
/*    filter: var(--green-filter);*/
/*}*/

/*img.select-btn.green.off {*/
/*    filter: none;*/
/*}*/

.select-btn.green.on {
    color: var(--background);
    background-color: var(--green);
}

.select-btn.green.off {
    color: var(--green);
    background-color: var(--clear-green);
    border: 2px solid var(--green);
}




.navigation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    min-height: 100px;
    background-color: var(--light-gray);
    z-index: 100;
}

.navigation {
    position: relative;
    width: 800px;
    max-width: 100%;
    height: 100%;
    margin: auto;
}

.navigation h2 {
    position: absolute;
    margin: 0;
    left: 20px;
    bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

.navigation-btn {
    position: absolute;
    margin: 0;
    right: 20px;
    bottom: 20px;
    display: flex;
}

.height-placeholder {
    position: relative;
    height: 20vh;
    min-height: 100px;
}

.text-input {
    width: calc(100% - 8px);
    outline-style: none;
    text-align: center;
    background-color: var(--background);
    border: 2px solid #d0d0d0;
    padding: 1px 2px;
    font-size: 16px;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
}

.text-input:focus {
    border: 2px solid var(--foreground);
}

.text-input:disabled {
    background-color: var(--clear-gray);
}

.form-items {
    position: relative;
    width: calc(100% - 40px);
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form-items label {
    width: 400px;
    max-width: 100%;
}

.form-items p {
    width: 400px;
    max-width: 100%;
    font-size: 14px;
    color: grey;
    margin: 14px 0 0;
}

.red {
    border: 2px solid var(--red);
    background-color: var(--clear-red);
}

.red-dot {
    background-color: var(--red);
    width: 6px;
    height: 6px;
    border-radius: 3px;
}

.orange-dot {
    background-color: var(--orange);
    width: 6px;
    height: 6px;
    border-radius: 3px;
}

.error {
    color: rgb(255, 59, 48);
    text-align: center;
}


